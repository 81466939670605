import { createContext, useEffect, useMemo, useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import { TestimonialPage } from "types/gatsby-alias"

export const TestimonialsContext = createContext<{
  activeTestimonialPage: TestimonialPage | null
  setActiveTestimonialPage: (page: TestimonialPage) => void
  setActiveVideo: (video: string) => void
  activeVideo: string | null
  activePage: string
  setActivePage: (url: string) => void
}>({
  activeTestimonialPage: null,
  setActiveTestimonialPage: () => null,
  setActiveVideo: () => null,
  activeVideo: "",
  activePage: "",
  setActivePage: () => "",
})

interface Props {
  children: React.ReactNode
}

export default function TestimonialsProvider({ children }: Props) {
  const [activeTestimonialPage, setActiveTestimonialPage] = useState<
    | Queries.TestimonialsQuery["allContentfulTestimonialPage"]["nodes"][number]
    | null
  >(null)
  const [activeVideo, setActiveVideo] = useState<string>("")
  const [activePage, setActivePage] = useState<string>("")

  const TestimonialsValues = useMemo(() => {
    return {
      activeTestimonialPage,
      activeVideo,
      setActiveVideo,
      setActiveTestimonialPage,
      activePage,
      setActivePage,
    }
  }, [
    activeTestimonialPage,
    activeVideo,
    setActiveVideo,
    setActiveTestimonialPage,
    activePage,
    setActivePage,
  ])

  const testimonials: Queries.TestimonialsQuery = useStaticQuery(graphql`
    query Testimonials {
      allContentfulTestimonialPage(
        filter: { contentful_id: { ne: "7vVUMO4Xm0tsnaXHJDqLuF" } }
      ) {
        nodes {
          contentful_id
          id
          pageURL
          testimonials {
            testimonialNumber
            buttonText
            contentful_id
            youTubeEmbed {
              contentful_id
              videoName
              youtubeVideoId
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (activePage) {
      testimonials.allContentfulTestimonialPage.nodes.forEach(page => {
        if (activePage.includes(page.pageURL || "zzzzzzzzzzz")) {
          setActiveTestimonialPage(page)
        } else {
          setActiveTestimonialPage(null)
        }
      })
    }
  }, [testimonials, activePage])

  return (
    <TestimonialsContext.Provider value={TestimonialsValues}>
      {children}
    </TestimonialsContext.Provider>
  )
}
